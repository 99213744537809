var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-app-bar',{staticStyle:{"z-index":"8"},attrs:{"app":"","dark":"","elevate-on-scroll":"","color":"primary"}},[(_vm.isSmallScreen)?_c('v-app-bar-nav-icon',{staticClass:"mr-1",on:{"click":function($event){$event.stopPropagation();_vm.drawer = !_vm.drawer}}}):_vm._e(),_c('v-avatar',{staticClass:"mr-4",attrs:{"size":"35","color":"secondary"},on:{"click":_vm.goHome}},[_c('v-img',{attrs:{"src":_vm.src}})],1),_c('v-toolbar-title',{on:{"click":_vm.goHome}},[_vm._v("Noah Hessel")]),_c('v-spacer'),(!_vm.isSmallScreen)?_c('v-toolbar-items',_vm._l((_vm.pages),function(ref){
var title = ref.title;
var icon = ref.icon;
var to = ref.to;
return _c('v-btn',{key:to,attrs:{"text":"","to":to,"small":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(_vm._s(icon))]),_vm._v(" "+_vm._s(title)+" ")],1)}),1):_vm._e()],1),(_vm.isSmallScreen)?_c('v-navigation-drawer',{style:(("margin-top: " + (_vm.$vuetify.application.top) + "px")),attrs:{"clipped":"","temporary":"","app":""},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-list',{attrs:{"nav":"","dense":""}},_vm._l((_vm.pages),function(ref){
var title = ref.title;
var icon = ref.icon;
var to = ref.to;
return _c('v-list-item',{key:to,attrs:{"text":"","to":to,"color":"accent"}},[_c('v-list-item-icon',{staticClass:"mr-3"},[_c('v-icon',[_vm._v(_vm._s(icon))])],1),_c('v-list-item-title',{staticClass:"text-subtitle-2"},[_vm._v(_vm._s(title))])],1)}),1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }